(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name phases.phase.controller:PhaseCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.phases.phase')
    .controller('PhaseCtrl', PhaseCtrl);

  function PhaseCtrl($state, actionButton, $stateParams, $filter, $mdToast, phase, Phase, AclService) {
    var vm = this;
    vm.ctrlName = 'PhaseCtrl';

    vm.phase = phase;
    vm.phase.iniDate = moment(vm.phase.iniDate).toDate();
    vm.phase.endDate = moment(vm.phase.endDate).toDate();

    vm.edit = false;
    vm.can = AclService.can;

    vm.buttons = {
      dial: true,
      icon: 'more_vert',
      tooltip: 'ACTIONS',
      actions: []
    };

    if (vm.phase.type === "LOCAL" && vm.can('LOCAL_PHASE', 'U') || (vm.phase.type === "REGIONAL" && vm.can('REGIONAL_PHASE', 'U'))) {
      vm.buttons.actions.push({
        buttonImage: 'images/ic_edit_black_48px.svg',
        attrClass: '',
        tooltip: 'EDIT',
        execute: enableEdit
      });
    }

    vm.buttons.actions.push({
      buttonImage: 'images/ic_assignment_black_48px.svg',
      attrClass: '',
      tooltip: 'PHASES.INSCRIBED_TEAMS_LABEL',
      stateToChange: 'home.phases.phase.inscribedTeams',
      changeState: true
    });
    vm.buttons.actions.push({
      buttonImage: 'images/ic_equalizer_black_48px.svg',
      attrClass: '',
      tooltip: 'PHASES.INSCRIBED_TEAMS_NUMBER_LABEL',
      stateToChange: 'home.phases.phase.inscribedTeamsNumber',
      changeState: true
    });

    function enableEdit() {
      vm.edit = true;
      actionButton.dial = false;
    }

    vm.updatePhase = function () {
      vm.phase.iniDate = moment(vm.phase.iniDate).format('YYYY-MM-DD');
      vm.phase.endDate = moment(vm.phase.endDate).format('YYYY-MM-DD');
      if (vm.iniDate > vm.endDate) {
        $mdToast.show(
          $mdToast.simple()
            .textContent($filter('translate')('ERROR_INVALID_DATE'))
            .position('bottom left')
            .hideDelay(3000)
        );
      } else {
        Phase.update({phaseId: $stateParams.phaseId}, vm.phase, function () {
            $mdToast.show(
              $mdToast.simple()
                .textContent($filter('translate')('PHASES.PHASE_SUCCESSFUL_UPDATE'))
                .position('bottom left')
                .hideDelay(3000)
            );
            $state.go($state.current, $stateParams, {reload: true});
          },
          function (error) {
            var errorMessage;
            if (error.status === 409) {
              errorMessage = 'PHASES.ERROR_INVALID_DATE_PHASE';
            } else if (error.status === 403) {
              errorMessage = 'NO_PERMISSIONS';
            } else {
              errorMessage = 'SERVER_ERROR';
            }
            $mdToast.show(
              $mdToast.simple()
                .textContent($filter('translate')(errorMessage))
                .position('bottom left')
                .hideDelay(3000)
            );
          });
      }
    };
  }
}());
